import { APICore } from "helpers/api/apiCore";
import LocalStorage from "helpers/api/LocalStorage";
import { useRedux } from 'hooks';
import Logout from "pages/account/Logout";
import RedirectLogout from "pages/account/RedirectLogout";
import { useEffect } from "react";
import { changeLayout, changeLayoutColor, changeLayoutWidth, changeSidebarTheme, changeSidebarType } from "redux/actions";
import { initDomains } from 'redux/domains/actions';
import { CustomThemeDto } from "types/CustomThemeDto";
import auth from "utils/auth";
import { parseJwt } from "utils/ParseToken";

type PrivateRouteProps = {
    Component: React.ComponentType;
    roles?: string[];
};

/**
 * Private Route forces the authorization before the route can be accessed
 * @param {*} param0
 * @returns
 */
const PrivateRoute = ({ Component: RouteComponent, roles, ...rest }: PrivateRouteProps) => {
    
    const { appSelector, dispatch } = useRedux();

    const accessTokenValidation = auth.useAccessTokenValidation(roles);

    const { domains, authToken } = appSelector((state) => ({
        domains: state.Domains.list,
        authToken: state.Auth.authorization.token,
    }));

    if (!domains && accessTokenValidation.isInRoles) {
        initDomains();
    }
    
    useEffect(() => {
        const userId = authToken && parseJwt(authToken || "").userId;
        if (!userId) return;
        APICore.get<CustomThemeDto>("api/UserPreferences", {params: { loggedInUserId: userId }}).then(
            (response) => {
                if (response.layoutType) {
                    dispatch(changeLayout(response.layoutType));
                }
                if (response.layoutColorScheme) {
                    dispatch(changeLayoutColor(response.layoutColorScheme));
                }
                if (response.widthMode) {
                    dispatch(changeLayoutWidth(response.widthMode));
                }
                if (response.leftSidebarTheme) {
                    dispatch(changeSidebarTheme(response.leftSidebarTheme));
                }
                if (response.leftSidebarType) {
                    dispatch(changeSidebarType(response.leftSidebarType));
                }
            }
        );
    }, []);

    /**
     * not logged in so redirect to login page with the return url
     */
    // if (isAuthed === false) {
    //     return <Navigate to={'/account/login'} state={{ from: location }} replace />;
    // }

    // check if route is restricted by role

    if (!accessTokenValidation.isLoggedIn || !LocalStorage.getLoggedInUser()) {
        return <Logout />
    }

    if (!accessTokenValidation.isInRoles) {
        // role not authorised so redirect to home page
        return <RedirectLogout url="account/access-denied" />
    }

    return <RouteComponent />
};

export default PrivateRoute;
