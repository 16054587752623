import Routes from 'routes/Routes';
// For Default import Saas.scss
import 'assets/scss/Saas.scss';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import config from '../src/config'
import { useLoadScript } from "@react-google-maps/api";
import { mapLibraries } from "./pages/routing/fleet-view/complements/FleetUtils";
import { Spinner } from "./components";
import { oAuthLogin } from 'redux/actions';
import useRedux from 'hooks/useRedux';
import { OAuthLogin } from 'helpers';
import Inactivity from 'pages/other/Inactivity';

const oktaAuth = new OktaAuth({
    pkce: false,
    authorizeUrl: `${config.OKTA_ISSUER}/authorize`,
    userinfoUrl: `${config.OKTA_ISSUER}/userinfo`,
    issuer: `${config.OKTA_ISSUER}`,
    clientId: config.OKTA_CLIENT_ID,
    clientSecret: config.OKTA_CLIENT_SECRET,
    redirectUri: `${window.location.origin}/login/callback`,
    responseType: ['token'],
    scopes: ['openid', 'email', 'profile'],
});

const timeout = 43_200_000
const promptBeforeIdle = 60_000

// import 'assets/scss/Creative.scss';
// import 'assets/scss/Modern.scss';
// @ts-ignore

const App = () => {

    const { dispatch } = useRedux();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: config.GOOGLE_MAPS_API_KEY!,
        version: 'beta',
        // @ts-ignore
        libraries: mapLibraries,
    })

    return (
        <Security oktaAuth={oktaAuth} restoreOriginalUri={(async (_oktaAuth, originalUri) => {
            
            const token = _oktaAuth.authStateManager.getAuthState()!.accessToken!.accessToken

            var user = await OAuthLogin({ idToken: token });

            dispatch(oAuthLogin(user, token));

            window.location.href = toRelativeUrl(originalUri || '/', window.location.origin);
        })}>
            {isLoaded ?
                <>
                    <Routes />
                    <Inactivity timeout={timeout} promptBeforeIdle={promptBeforeIdle} />
                </> : <Spinner className='middle_center_spinner' />}
        </Security>);
};

export default App;
