import { useOktaAuth } from '@okta/okta-react';
import { Navigate } from 'react-router-dom';

const Root = () => {
    const { authState } = useOktaAuth()

    const isClaimRoles = authState?.accessToken ? true : false

    return <Navigate to={`/${isClaimRoles ? "home" : "account/login"}`} />;
};

export default Root;
