import { APICore } from 'helpers/api/apiCore';
import { store } from 'redux/store';
import { parseJwt } from 'utils/ParseToken';
import {
    Domain,
    DomainMetro,
    DomainOnFleet,
    DomainMerchant,
    DomainFailureReasons,
    DomainEventTypes,
    DomainOnFleetDriver,
    PricingRule, DomainPodsFailureReason, DomainEventImageTypes,
} from './constants';
const GetDomain = <T>(route: string, params: any = {}) => APICore.get<T>(route, { ...params, hideError: true });

const DomainsService = {
    getRoutingParties: () => {
        return GetDomain<Domain[]>(`api/Helpers/third-party-carrier-dropdown`);
    },
    getCities: (stateId: number) => {
        return GetDomain<Domain[]>(`api/Helpers/cities-dropdown/${stateId}`);
    },
    getCompanies: () => {
        return GetDomain<Domain[]>('api/Helpers/companies-dropdown');
    },
    getCountries: () => {
        return GetDomain<Domain[]>('api/Helpers/countries-dropdown');
    },
    getMetroCodes: () => {
        return GetDomain<DomainMetro[]>('api/Helpers/metro-codes-dropdown');
    },
    getMetroCodesIds: () => {
        return GetDomain<DomainMetro[]>('api/Helpers/metro-codes-with-ids-dropdown');
    },
    getMetroZones: () => {
        return GetDomain<DomainMetro[]>('api/Helpers/metro-zones-dropdown');
    },
    getOnFleetTeams: () => {
        return GetDomain<DomainOnFleet[]>('api/Helpers/onfleet-teams-dropdown');
    },
    getOnFleetTeamDrivers: (teamId: string) => {
        return GetDomain<DomainOnFleetDriver[]>(`api/Helpers/onfleet-team-drivers-dropdown/${teamId}`);
    },
    getOpsHubs: () => {
        return GetDomain<Domain[]>('api/Helpers/ops-hubs-dropdown');
    },
    getHubMetroConfigurations: () => {
        return GetDomain<Domain[]>('api/Helpers/hub-metro-configurations-dropdown');
    },
    getServiceLevels: () => {
        return GetDomain<Domain[]>('api/Helpers/service-levels-dropdown');
    },
    getServiceLevelsToken: () => {
        return GetDomain<Domain[]>('api/Helpers/service-levels-tokens-dropdown');
    },
    getServiceLevelsByCompanyId: (companyId: number) => {
        return GetDomain<Domain[]>(`api/Helpers/service-levels-dropdown/${companyId}`);
    },
    getStates: () => {
        return GetDomain<Domain[]>('api/Helpers/states-dropdown');
    },
    getStateCodes: () => {
        return GetDomain<Domain[]>('api/Helpers/states-codes-dropdown');
    },
    getStateCodesAbbr: () => {
        return GetDomain<Domain[]>('api/Helpers/states-abbr-dropdown');
    },
    getMerchants: () => {
        return GetDomain<DomainMerchant[]>('api/Helpers/merchants-dropdown');
    },
    getCurrencies: () => {
        return GetDomain<Domain[]>('api/Helpers/currencies-dropdown');
    },
    getZipCodeExceptionTypes: () => {
        return GetDomain<Domain[]>('api/Helpers/zip-code-exception-types-dropdown');
    },
    getUserRoles: () => {
        return GetDomain<Domain[]>('api/Helpers/roles-dropdown');
    },
    getZipCodes: () => {
        return GetDomain<Domain[]>('api/Helpers/zip-codes-dropdown');
    },
    getClaimStatus: () => {
        return GetDomain<Domain[]>('api/Helpers/claim-status-dropdown');
    },
    getFailureResons: () => {
        return GetDomain<DomainFailureReasons[]>('api/Helpers/failure-reason-dropdown');
    },
    getEventTypes: () => {
        return GetDomain<DomainEventTypes[]>('api/Helpers/event-types-dropdown');
    },
    getClaimReasons: () => {
        return GetDomain<Domain[]>('api/Helpers/claim-reasons-dropdown');
    },
    getPodsFailureReasons: () => {
        return GetDomain<DomainPodsFailureReason[]>('api/Events/GetPodReviewFailureCodes');
    },
    getEventImageTypes: () => {
        return GetDomain<DomainEventImageTypes[]>('api/Events/images/types');
    },
    getThirdPartyRoutingSearches: () => {
        const userId = parseJwt(store.getState().Auth?.authorization?.token || '').userId;
        return GetDomain<Domain[]>('api/ThirdPartyCarrierShipments/SavedSearches', {
            params: {
                loggedInUserId: userId,
            },
        });
    },
    getRoutingTaskSearches: () => {
        const userId = parseJwt(store.getState().Auth?.authorization?.token || '').userId;
        const userEmail = store.getState().Auth?.user?.email;
        return GetDomain<Domain[]>('api/Helpers/routing-tasks-searches-dropdown', {
            params: {
                loggedInUserEmail: userEmail,
            },
        });
    },

    getELSSearches: () => {
        const userId = parseJwt(store.getState().Auth?.authorization?.token || '').userId;
        const userEmail = store.getState().Auth?.user?.email;
        return GetDomain<Domain[]>('api/Helpers/els-searches-dropdown', {
            params: {
                loggedInUserEmail: userEmail,
            },
        });
    },

    getMassUnits: () => {
        return GetDomain<Domain[]>('api/Helpers/mass-units-dropdown');
    },
    getDistanceUnits: () => {
        return GetDomain<Domain[]>('api/Helpers/distance-units-dropdown');
    },
    getEventTypesWithDesc: () => {
        return GetDomain<{ id: number; code: string; description: string }[]>(
            'api/Helpers/event-types-with-decriptions-dropdown'
        );
    },
    getPricingRules: () => {
        return GetDomain<{ result: PricingRule[] }>('api/DynamicPricing/PricingRuleDetails');
    },
};

export default DomainsService;
