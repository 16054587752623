export type DomainPayload = {
    domain?: {
        name: string;
        value: Domain[] | DomainMap | { id: number; code: string; description: string }[];
    } | null;
};

export enum DomainActionTypes {
    UPDATE_CITIES_DOMAIN = 'domains/update-cities',
    UPDATE_COMPANIES_DOMAIN = 'domains/update-companies',
    UPDATE_ROUTING_PARTIES_DOMAIN = 'domains/update-routing_parties',
    UPDATE_COUNTRIES_DOMAIN = 'domains/update-countries',
    UPDATE_METRO_CODES_DOMAIN = 'domains/update-metro-codes',
    UPDATE_METRO_CODEIDS_DOMAIN = 'domains/update-metro-codes-ids',
    UPDATE_METRO_ZONES_DOMAIN = 'domains/update-metro-zones',
    UPDATE_ONFLEET_TEAMS_DOMAIN = 'domains/update-onfleet-teams',
    UPDATE_SERVICE_LEVELS_DOMAIN = 'domains/update-service-levels',
    UPDATE_SERVICE_LEVELS_DOMAIN_TOKEN = 'domains/update-service-levels-token',
    UPDATE_STATES_DOMAIN = 'domains/update-states',
    UPDATE_STATES_ABBR_DOMAIN = 'domains/update-states-abbr',
    UPDATE_STATE_CODES_DOMAIN = 'domains/update-state-codes',
    UPDATE_MERCHANTS_DOMAIN = 'domains/update-merchants',
    UPDATE_CURRENCIES_DOMAIN = 'domains/update-currencies',
    UPDATE_ZIP_CODE_EXCEPTION_TYPES = 'domains/update-zip-code-exception-types',
    UPDATE_USER_ROLES = 'domains/update-user-roles',
    UPDATE_ZIP_CODES = 'domains/update-zip-coes',
    UPDATE_CLAIM_STATUS = 'domains/update-claim-status',
    UPDATE_FAILURE_REASON = 'domains/update-failure-reason',
    UPDATE_EVENT_TYPES = 'domain/update-event-types',
    UPDATE_NOTE_TYPES = 'domain/update-note-types',
    UPDATE_CLAIM_REASONS = 'domain/update-claim-reasons',
    UPDATE_ROUTING_TASK_SEARCH = 'domain/update-routing-task-search',
    UPDATE_ELS_SEARCH = 'domain/update-els-search',
    UPDATE_THIRD_PARTY_SHIPMENTS_SEARCHES = 'domain/update-third-party-shipments_searches',
    UPDATE_MASS_UNITS = 'domain/update-mass-units',
    UPDATE_DISTANCE_UNITS = 'domain/update-distance-units',
    UPDATE_EVENT_TYPES_WITH_DESC = 'domain/update-event-types-desc',
	UPDATE_PRICING_RULE = 'domains/update-pricing-rule',
    UPDATE_OPS_HUB_DOMAIN = 'domains/update-ops-hubs',
    UPDATE_PODS_FAILURE_REASONS = 'domains/update-pods-failure-reasons',
    UPDATE_EVENT_IMAGE_TYPES = 'domains/update-event-image-types',
    UPDATE_HUB_METRO_CONFIGURATIONS = 'domain/hub-metro-configurations'
}

export type Domain = {
    id: number;
    name: string;
};
export type DomainAbbr = { code: string; name: string };

export type DomainMap = {
    [key: string]: Domain[];
};

export type DomainMetro = {
    disabled: boolean | null;
    group: null;
    selected: boolean | null;
    text: string | null;
    value: string | null;
} & Domain;

export type DomainOnFleet = {
    timeCreated: number | null;
    timeLastModified: number | null;
    organization: null;
    type: null;
    activeTask: null;
    tasks: string[] | null;
    team: null;
    hub: string | null;
    enabledSelfAssignment: boolean | null;
} & Domain;

export type DomainOnFleetDriver = {
    timeCreated: number;
    timeLastModified: number;
    organization: string;
    type: string;
    activeTask: string;
    tasks: string[];
    team: string;
    hub: string;
    enabledSelfAssignment: boolean;
    onDuty: boolean;
    location: number[];
} & Domain;

export type DomainMerchant = {
    disabled: boolean | null;
    group: null;
    selected: boolean | null;
    text: string | null;
    value: string | null;
} & Domain;

export type DomainFailureReasons = {
    disabled: boolean | null;
    group: null;
    selected: boolean | null;
    text: string | null;
    value: string | null;
} & Domain;

export type DomainEventTypes = {
    code: string | null;
} & Domain;

export type PricingRule = {
    pricingRuleId: string,
    pricingRuleName: string,
    pricingRuleType:string;
    pricingUnit: string,
    rate: number,
    companyNames: string,
}& Domain;

export type DomainPodsFailureReason = {
    id: number;
    code: number;
    reason: string;
    description: string;
} & Domain;

export type DomainEventImageTypes = {
    id: number;
    code: string;
    description: string;
} & Domain;

export type DomainList = {
    // TODO: Map for cities
    cities?: DomainMap | null;
    companies?: Domain[] | null;
    routingParties?:Domain[] | null;
    countries?: Domain[] | null;
    metroCodes?: DomainMetro[] | null;
    metroCodesIds?: DomainMetro[] | null;
    metroZones?: DomainMetro[] | null;
    onFleetTeams?: DomainOnFleet[] | null;
    serviceLevels?: Domain[] | null;
    states?: Domain[] | null;
    stateCodes?: Domain[] | null;
    stateAbbr?: DomainAbbr[] | null;
    merchants?: DomainMerchant[] | null;
    currencies?: Domain[] | null;
    zipCodeExectionTypes?: Domain[] | null;
    userRoles?: Domain[] | null;
    zipCodes?: Domain[] | null;
    claimStatus?: Domain[] | null;
    failureReason?: DomainFailureReasons[] | null;
    eventTypes?: DomainEventTypes[] | null;
    noteTypes?: Domain[] | null;
    claimReasons?: Domain[] | null;
    thirdPartyShipmentsSearches?: Domain[] | null;
    routingTaskSearches?: Domain[] | null;
    elsSearches?: Domain[] | null;
    massUnits?: Domain[] | null;
    distanceUnits?: Domain[] | null;
    eventTypesWithDesc?: { id: number; code: string; description: string }[];
    serviceLevelsToken?: { token: string; name: string }[];
	pricingRules?: PricingRule[];
    opsHubs?: Domain[] | null;
    podsFailureReasons?: DomainPodsFailureReason[] | null;
    eventImageTypes?: DomainEventImageTypes[] | null;
    hubMetroConfigurations?: Domain[] | null;
};

export type DomainState = {
    list: DomainList | null;
};

export type DomainReducer = keyof DomainList;
