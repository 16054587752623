import { useOktaAuth } from '@okta/okta-react'

const rolesClaimName = "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
const groupsClaimName = "http://schemas.microsoft.com/ws/2008/06/identity/claims/groups"

function useIsInRoles(roles: string[] | string | undefined): boolean {

    const { authState } = useOktaAuth()

    const claimRoles = authState?.accessToken?.claims[rolesClaimName] ?? []
    const arrayOfRoles = (claimRoles instanceof Array) ? claimRoles : [claimRoles]
    const checksRoles = (roles instanceof Array) ? roles : [roles]
    const isContains = roles == undefined || roles?.length == 0 || arrayOfRoles.some(x => checksRoles?.some(role => role == x))

    return isContains
}

function useIsInGroups(groups: string[] | string | undefined | null): boolean {

    const { authState } = useOktaAuth()

    const claimGroups = authState?.accessToken?.claims[groupsClaimName] ?? []
    const arrayOfGroups = (claimGroups instanceof Array) ? claimGroups : [claimGroups]
    const checksGroups = (groups instanceof Array) ? groups : [groups]
    const isContains = groups == null || groups == undefined || groups?.length == 0 || arrayOfGroups.some(x => checksGroups?.some(role => role == x))

    return isContains
}

export type AccessTokenValidationParameters = {
    isLoggedIn: boolean
    isInRoles: boolean
}

function useAccessTokenValidation(roles: string[] | string | undefined): AccessTokenValidationParameters {

    const { authState, oktaAuth } = useOktaAuth()

    const claimRoles = authState?.accessToken?.claims[rolesClaimName] ?? []
    const arrayOfRoles = (claimRoles instanceof Array) ? claimRoles : [claimRoles]
    const checksRoles = (roles instanceof Array) ? roles : [roles]
    const isContains = roles == undefined || roles?.length == 0 || arrayOfRoles.some(x => checksRoles?.some(role => role == x))
    const isLoggedIn = (authState?.accessToken && !oktaAuth.tokenManager.hasExpired(authState!.accessToken!)) == true;

    return {
        isLoggedIn: isLoggedIn,
        isInRoles: isContains
    }
}

export default { useIsInRoles, useIsInGroups, useAccessTokenValidation }
