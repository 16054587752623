import { MapOptionsModel, MARKERS_ICON } from "../../pages/routing/fleet-view/complements/FleetUtils";
import { GFEDriver } from "../../pages/routing/fleet-view/listing/FleetViewVehicleDetails";
import config from "../../config";
import { DateTypeOnChange } from "../../components/Datepicker";
import { SetStateAction } from "react";
import { formatDate } from "../../utils";

export const defaultMapOptionsValues: MapOptionsModel = {
  showAnticipatedRoutePolyline: true,
  showTakenRoutePolyline: true,
  vehicleMarker: MARKERS_ICON.USE_PNG_VEHICLE,
  destinationMarker: MARKERS_ICON.USE_DEFAULT,
  pingMarker: MARKERS_ICON.USE_PNG_VEHICLE,
  vehicleDetailsMarker: MARKERS_ICON.USE_PNG_DETAILS_VEHICLE,
  outcomeMarker: MARKERS_ICON.USE_PNG_OUTCOME,
  noClear: true,
  mapId: config.GOOGLE_MAPS_MAP_ID,
};

export enum MeasureUnitType {
  METRIC = 'metric',
  IMPERIAL = 'imperial',
}

export type MapError = {
  title: string;
  errors: string[];
}

export type CoordinatePoint = {
  latitude: number | null,
  longitude: number | null,
}

export interface VehicleInterface {
  name: string;
  driver: {[key: string]: string|null} | null,
  time: string | null | undefined;
  driverStatistics: {[key: string]: number|null} | null,
  location:  google.maps.LatLng | google.maps.LatLngLiteral | null | undefined,
  speed: number | undefined,
  navigationStatus: string | null | undefined,
  locationStale: boolean | undefined,
  timeSinceLastLocationUpdate: number | string | null | undefined,
}
export interface VehicleStatisticsInterface {
  totalTasks?: number,
  failedTasks?: number,
  successfulTasks?: number,
  remainingTasks?: number,
}

export interface VehicleDetailsModel {
  vehicleId?: string;
  driver?: GFEDriver | {[key: string]: string|null} | undefined,
  tasks?: any[],
  remainingSegments?: any[],
  statistics?: {[key: string]: number | string | null} | undefined,
  attributes?: {[key: string]: number | string | null} | undefined,
}

export interface UncompletedTaskDetails {
  trackingId: string;
  taskDuration?: string | number;
  drivingDistanceMeters?: string | number;
  drivingDuration?: string | number;
}

export const defaultVehicleDetailsValues: VehicleDetailsModel = {
  vehicleId: '',
  driver: {},
  tasks: [],
  remainingSegments: [],
  statistics: {},
  attributes: {}
}

export interface FullCoordinatesModel {
  center?: google.maps.LatLngLiteral,
  zoom?: number | null | undefined,
  northEast?: google.maps.LatLng | undefined,
  southWest?: google.maps.LatLng | undefined,
}

export const center: google.maps.LatLngLiteral = {
  lat: 39.2245625,
  lng: -100.3927786,
};
export const zoom = 4.5;
export const defaultMapCoordinatesValues: FullCoordinatesModel | undefined = {
  center: center,
  zoom: zoom,
  northEast: undefined,
  southWest: undefined,
};

export const defaultFilterValues: FilterValuesInterface = {
  driver: {
    input: '',
    metro: undefined,
    date: [new Date(), new Date()]
  },
}

export type FilterValuesInterface = {
  driver?: {
    input?: any,
    metro?: SetStateAction<undefined>,
    date?: [DateTypeOnChange, DateTypeOnChange],
    driverStatus?: {
      label: string,
      value: string,
    },
  },
}

/** ------------------------------------------------------------------------------------ */
export enum MapActionTypes {
  SET_LOADING = 'fleetView/loading',
  SET_ERROR = "fleetView/error",
  SET_MAP_IS_MOVING = "fleetView/mapIsMoving",
  SET_MARKER_TRACKING_ID = "fleetView/trackingId",
  SET_MAP_FLEET_OPTIONS = "fleetView/mapFleetOptions",
  SET_ZOOM_LEVEL = "fleetView/zoomLevel",
  SET_VEHICLE_LIST = "fleetView/vehicleList",
  SET_VEHICLE_LIST_STATISTICS = "fleetView/vehicleListStatistics",
  SET_VEHICLE_ID = "fleetView/vehicleId",
  SET_VEHICLE_DETAILS = "fleetView/vehicleDetails",
  SET_TASKS_DETAILS = "fleetView/tasksDetails",
  SET_UNCOMPLETED_TASKS_DETAILS = "fleetView/uncompletedTasksDetails",
  SET_TASKS_MARKERS = "fleetView/tasksMarkers",
  SET_TASKS_ID_LIST = "fleetView/taskIdList",
  SET_TASKS_OUTCOME_LIST = "fleetView/taskOutcomeList",
  SET_UPDATE_MARKERS_LABEL = "fleetView/updateMarkersLabel",
  SET_FLEET_MARKER_VEHICLE_ID = "fleetView/fleetMarkerVehicleId",
  SET_TASK_MARKER_VEHICLE_ID = "fleetView/taskMarkerVehicleId",
  SET_MAP_COORDINATE_VALUES = "fleetView/mapCoordinateValues",
  SET_IS_DEFAULT_CENTERED_MAP = "fleetView/isDefaultCenteredMap",
  SET_SHOW_OUTCOME_MARKERS_ON_DETAILS = "fleetView/showOutcomeMarkersOnDetails",
  SET_TOGGLE_MEASURE_UNIT = "fleetView/toggleMeasureUnit",
  SET_FILTER_VALUES = "fleetView/filterValues",
}

export type MapState = {
  loading?: boolean,
  error?: MapError | null,
  mapIsMoving?: boolean,
  trackingId?: string;
  fleetMapOptions?: MapOptionsModel;
  zoomLevel?: number;
  vehicleList?: VehicleInterface[] | null;
  vehicleListStatistics?: VehicleStatisticsInterface | undefined;
  vehicleId?: string,
  vehicleDetails?: VehicleDetailsModel,
  tasksDetails?: any[],
  uncompletedTasksDetails?: UncompletedTaskDetails[],
  tasksMarkers?: [],
  taskIdList?: string,
  taskOutcomeList?: any[],
  updateMarkersLabel?: boolean,
  fleetMarkerVehicleId?: string,
  taskMarkerVehicleId?: string,
  mapCoordinateValues?: FullCoordinatesModel | undefined,
  isDefaultCenteredMap?: boolean,
  showOutcomeMarkersOnDetails?: boolean,
  toggleMeasureUnit?: MeasureUnitType,
  filterValues?: FilterValuesInterface;
}
