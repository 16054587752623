import cogoToast from 'cogo-toast';
import { Loader } from 'components';
import { useRedux } from 'hooks';
import { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { setAppError } from 'redux/actions';
import { AllRoutes } from './index';

const Routes = () => {
    const { dispatch, appSelector } = useRedux();

    const { showLoading, error } = appSelector((state) => ({
        showLoading: state.Application.loading,
        error: state.Application.error,
    }));

    useEffect(() => {
        if (error) {
            const persistTime = error?.persistError ? 120000 : 10000;
            const errorElement = (
                <div className="d-flex flex-column">
                    {Array.isArray(error.errors)
                        ? error.errors.map((err) => {
                              if (typeof err !== 'string') return null;
                              return <span key={`${(Math.random() * 1000).toFixed()}`}>{err}</span>;
                          })
                        : Object.values(error.errors).map((err) => {
                              if (typeof err !== 'string' && !Array.isArray(err)) return null;
                              return <span key={`${(Math.random() * 1000).toFixed()}`}>{err.toString()}</span>;
                          })}
                </div>
            );

            const notification = !error.warning ? cogoToast.error : cogoToast.warn;
            const heading = error.warning ? 'Warning' : 'Error';
            
            const { hide } = notification(errorElement, {
                position: 'top-right',
                onClick: () => {
                    clearTimeout(timeout);
                    hide && hide();
                    dispatch(setAppError(null));
                },
                heading,
                hideAfter: persistTime,
            });

            const timeout = setTimeout(() => {
                hide && hide();
                dispatch(setAppError(null));
            }, persistTime);
        }
    }, [error]);

    return (
        <BrowserRouter>
            <AllRoutes />
        </BrowserRouter>
    );
};

export default Routes;
