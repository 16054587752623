import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { DefaultLayout, VerticalLayout, HorizontalLayout, DetachedLayout, FullLayout } from 'layouts';
import PrivateRoute from './PrivateRoute';
import Root from './Root';
import {
    LayoutTypes,
    MANAGER_ROLE,
    OPERATIONS_ROLE,
    ROUTING_ROLE,
    SALES_ROLE,
    CUSTOMER_SERVICE_ROLE,
} from 'appConstants';
import { useRedux } from 'hooks';
import { LoginCallback } from '@okta/okta-react';
import RedirectLogout from 'pages/account/RedirectLogout';
import LocalStorage from 'helpers/api/LocalStorage';
import Redirecting from 'pages/account/Redirecting';
// lazy load all the views

// auth
const Login = React.lazy(() => import('pages/account/Login'));
const AccessDenied = React.lazy(() => import('pages/account/AccessDenied'));
const Logout = React.lazy(() => import('pages/account/Logout'));
const Confirm = React.lazy(() => import('pages/account/Confirm'));
const LockScreen = React.lazy(() => import('pages/account/LockScreen'));
const AuthError = React.lazy(() => import('pages/account/AuthError'));

const Confirm2 = React.lazy(() => import('pages/account/Confirm2'));
const LockScreen2 = React.lazy(() => import('pages/account/LockScreen2'));

// dashboard
// const AnalyticsDashboard = React.lazy(() => import('pages/dashboard/Analytics'));
// const EcommerceDashboard = React.lazy(() => import('pages/dashboard/Ecommerce'));
// const ProjectDashboard = React.lazy(() => import('pages/dashboard/Project'));
// const EWalletDashboard = React.lazy(() => import('pages/dashboard/E-Wallet'));

// apps
const CalendarApp = React.lazy(() => import('pages/apps/Calendar'));
const ProjectList = React.lazy(() => import('pages/apps/Projects/List'));
const ProjectDetail = React.lazy(() => import('pages/apps/Projects/Detail/'));
const ProjectGannt = React.lazy(() => import('pages/apps/Projects/Gantt'));
const ProjectForm = React.lazy(() => import('pages/apps/Projects/ProjectForm'));

// - chat
const ChatApp = React.lazy(() => import('pages/apps/Chat/'));

// -crm
const CRMDashboard = React.lazy(() => import('pages/apps/CRM/Dashboard'));
const CRMProjects = React.lazy(() => import('pages/apps/CRM/Projects'));
const CRMManagement = React.lazy(() => import('pages/apps/CRM/Management'));
const CRMClients = React.lazy(() => import('pages/apps/CRM/Clients'));
const CRMOrderList = React.lazy(() => import('pages/apps/CRM/OrderList'));

// - ecommece pages
const EcommerceProducts = React.lazy(() => import('pages/apps/Ecommerce/Products'));
const ProductDetails = React.lazy(() => import('pages/apps/Ecommerce/ProductDetails'));
const Orders = React.lazy(() => import('pages/apps/Ecommerce/Orders'));
const OrderDetails = React.lazy(() => import('pages/apps/Ecommerce/OrderDetails'));
const Customers = React.lazy(() => import('pages/apps/Ecommerce/Customers'));
const Cart = React.lazy(() => import('pages/apps/Ecommerce/Cart'));
const Checkout = React.lazy(() => import('pages/apps/Ecommerce/Checkout/'));
const Sellers = React.lazy(() => import('pages/apps/Ecommerce/Sellers'));

// - email
const Inbox = React.lazy(() => import('pages/apps/Email/Inbox'));
const EmailDetail = React.lazy(() => import('pages/apps/Email/Detail'));

// - social
const SocialFeed = React.lazy(() => import('pages/apps/SocialFeed/'));

// - tasks
const TaskList = React.lazy(() => import('pages/apps/Tasks/List/'));
const TaskDetails = React.lazy(() => import('pages/apps/Tasks/Details'));
const Kanban = React.lazy(() => import('pages/apps/Tasks/Board/'));
// - file
const FileManager = React.lazy(() => import('pages/apps/FileManager'));

// pages
const Profile = React.lazy(() => import('pages/profile'));
const Profile2 = React.lazy(() => import('pages/profile2'));
const ProfileOkta = React.lazy(() => import('pages/profile-okta'));
const ErrorPageNotFound = React.lazy(() => import('pages/error/PageNotFound'));
const ErrorPageNotFoundAlt = React.lazy(() => import('pages/error/PageNotFoundAlt'));
const ServerError = React.lazy(() => import('pages/error/ServerError'));

//Role - Menagments
const Groups = React.lazy(() => import('pages/roles-managment/Groups/Groups'));
const Permissions = React.lazy(() => import('pages/roles-managment/Permissions/Permissions'));

//customer - services
const ServiceLevelExceptions = React.lazy(() => import('pages/customer-services/service-levels-exceptions'));
// const TrackShipment = React.lazy(() => import('pages/customer-services/track-shipment'));
const TrackShipments = React.lazy(() => import('pages/customer-services/track-shipments'));

const ServiceLevels = React.lazy(() => import('pages/customer-services/service-levels'));
const SftpView = React.lazy(() => import('pages/customer-services/sftp/SftpView'));
const EventTypes = React.lazy(() => import('pages/customer-services/event-types/EventTypes'));
const AddressCorrectionsNotifications = React.lazy(
    () => import('pages/customer-services/address-corrections-notifications/AddressCorrectionsNotifications')
);
const PodReview = React.lazy(() => import('pages/customer-services/pod-review/PodReview'));

// Routing
const Pickups = React.lazy(() => import('pages/routing/pickups'));
const RoutingTasks = React.lazy(() => import('pages/routing/routing-tasks/RoutingTasks'));
const RouteSolutions = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutions'));
const RouteSolutionsV2 = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutionsV2'));
const RouteSolutionMap = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutionMap'));
const RouteSolutionDetailV2 = React.lazy(() => import('pages/routing/fleet-engine/RouteSolutionDetailV2'));
const FleetView = React.lazy(() => import('pages/routing/fleet-view/FleetView'));
const ZipBasedRouting = React.lazy(() => import('pages/fleet-ops/zip-based-routing'));
const DriverAssignment = React.lazy(() => import('pages/fleet-ops/driver-assignment'));
const PackageInquiry = React.lazy(() => import('pages/fleet-ops/package-inquiry'));

const ThirdPartyRouting = React.lazy(() => import('pages/routing/Third-party-routing/ThirdPartyRouting.tsx'));

// Dashboards
const WarehouseMetrics = React.lazy(() => import('pages/dashboard/warehouse-metrics/WarehouseMetrics'));

//Rates Management
const BasicRates = React.lazy(() => import('pages/rates-management/BasicRates'));
//Dynamic Pricing
const AllPricingRules = React.lazy(() => import('pages/dynamic-pricing/AllPricingRules'));
const AllCompanyPricingRules = React.lazy(() => import('pages/dynamic-pricing/AllCompanyPricingRules'));
const CreatePricingRule = React.lazy(() => import('pages/dynamic-pricing/CreatePricingRule'));
const ViewActivePricingRule = React.lazy(() => import('pages/dynamic-pricing'));
const CompanyDiscounts = React.lazy(() => import('pages/dynamic-pricing/GrossPrice'));

// Better Trucks
const Companies = React.lazy(() => import('pages/better-trucks/companies/Companies'));
const CompanyDetails = React.lazy(() => import('pages/better-trucks/companies/CompanyDetails'));
const Cities = React.lazy(() => import('pages/better-trucks/cities/Cities'));
const Shipments = React.lazy(() => import('pages/better-trucks/shipments/index'));
const ELSRoutingTasks = React.lazy(() => import('pages/better-trucks/routing-tasks/index'));
const Home = React.lazy(() => import('pages/home/simple-routing-tasks/index'));
const UnAttendedPackages = React.lazy(() => import('pages/home'));
const TemplatesTasks = React.lazy(() => import('pages/better-trucks/templates/index'));

const Claims = React.lazy(() => import('pages/better-trucks/claims'));

//Third Party Carriers
const Carriers = React.lazy(() => import('pages/third-party-carriers/carriers/Carriers'));
const CarrierZips = React.lazy(() => import('pages/third-party-carriers/carrier-zips/CarrierZips'));

const CompanyHubs = React.lazy(() => import('pages/better-trucks/hubs/CompanyHubs'));
const Users = React.lazy(() => import('pages/better-trucks/users/Users'));
const ZipCodes = React.lazy(() => import('pages/better-trucks/zip-codes/ZipCodes'));
const ZipCodeManagement = React.lazy(() => import('pages/better-trucks/zip-codes-exception/ZipCodeException'));
const ModifyZipcodes = React.lazy(() => import('pages/better-trucks/modify-zip-codes/index'));
const Vehicles = React.lazy(() => import('pages/better-trucks/vehicles/Vehicles'));
const OpsHubs = React.lazy(() => import('pages/orphan-labels/ops-hubs'));

// Orphan Labels
//const OrphanLabels = React.lazy(() => import('pages/orphan-labels/verify-labels/OrphanLabels'));
const OrphansPackage = React.lazy(() => import('pages/orphan-labels/verify-label-add-package/OrphansPackage'));
//const APICustomers = React.lazy(() => import('pages/orphan-labels/api-customers'));
const ContainerLabels = React.lazy(() => import('pages/orphan-labels/container-labels'));
const Labels = React.lazy(() => import('pages/orphan-labels/labels'));
const ContainerParcels = React.lazy(() => import('pages/orphan-labels/labels/ContainerParcels'));
const CompanyContainerLabels = React.lazy(() => import('pages/orphan-labels/container-company'));
const TrackingContainers = React.lazy(() => import('pages/orphan-labels/tracking-containers'));
const ContainerHubConfig = React.lazy(() => import('pages/orphan-labels/hub-metro-mapping'));
const LineHaulConfig = React.lazy(() => import('pages/orphan-labels/line-haul'));

// - other
const Invoice = React.lazy(() => import('pages/other/Invoice'));
const FAQ = React.lazy(() => import('pages/other/FAQ'));
const Pricing = React.lazy(() => import('pages/other/Pricing'));
const Maintenance = React.lazy(() => import('pages/other/Maintenance'));
const Starter = React.lazy(() => import('pages/other/Starter'));
const PreLoader = React.lazy(() => import('pages/other/PreLoader/'));
const Timeline = React.lazy(() => import('pages/other/Timeline'));

const Landing = React.lazy(() => import('pages/landing/'));

// uikit
const Accordions = React.lazy(() => import('pages/uikit/Accordions'));
const Alerts = React.lazy(() => import('pages/uikit/Alerts'));
const Avatars = React.lazy(() => import('pages/uikit/Avatars'));
const Badges = React.lazy(() => import('pages/uikit/Badges'));
const Breadcrumbs = React.lazy(() => import('pages/uikit/Breadcrumb'));
const Buttons = React.lazy(() => import('pages/uikit/Buttons'));
const Cards = React.lazy(() => import('pages/uikit/Cards'));
const Carousels = React.lazy(() => import('pages/uikit/Carousel'));
const Dropdowns = React.lazy(() => import('pages/uikit/Dropdowns'));
const EmbedVideo = React.lazy(() => import('pages/uikit/EmbedVideo'));
const Grid = React.lazy(() => import('pages/uikit/Grid'));
const ListGroups = React.lazy(() => import('pages/uikit/ListGroups'));
const Modals = React.lazy(() => import('pages/uikit/Modals'));
const Notifications = React.lazy(() => import('pages/uikit/Notifications'));
const Offcanvases = React.lazy(() => import('pages/uikit/Offcanvas'));
const Placeholders = React.lazy(() => import('pages/uikit/Placeholders'));
const Paginations = React.lazy(() => import('pages/uikit/Paginations'));
const Popovers = React.lazy(() => import('pages/uikit/Popovers'));
const Progress = React.lazy(() => import('pages/uikit/Progress'));
const Ribbons = React.lazy(() => import('pages/uikit/Ribbons'));
const Spinners = React.lazy(() => import('pages/uikit/Spinners'));
const Tabs = React.lazy(() => import('pages/uikit/Tabs'));
const Tooltips = React.lazy(() => import('pages/uikit/Tooltips'));
const Typography = React.lazy(() => import('pages/uikit/Typography'));
const DragDrop = React.lazy(() => import('pages/uikit/DragDrop'));
const RangeSliders = React.lazy(() => import('pages/uikit/RangeSliders'));
const Ratings = React.lazy(() => import('pages/uikit/Ratings'));

// icons
const Dripicons = React.lazy(() => import('pages/icons/Dripicons'));
const MDIIcons = React.lazy(() => import('pages/icons/MDIIcons'));
const Unicons = React.lazy(() => import('pages/icons/Unicons'));

// forms
const BasicForms = React.lazy(() => import('pages/forms/Basic'));
const FormAdvanced = React.lazy(() => import('pages/forms/Advanced'));
const FormValidation = React.lazy(() => import('pages/forms/Validation'));
const FormWizard = React.lazy(() => import('pages/forms/Wizard'));
const FileUpload = React.lazy(() => import('pages/forms/FileUpload'));
const Editors = React.lazy(() => import('pages/forms/Editors'));

// charts
const ApexChart = React.lazy(() => import('pages/charts/Apex'));
const ChartJs = React.lazy(() => import('pages/charts/ChartJs'));

// tables
const BasicTables = React.lazy(() => import('pages/tables/Basic'));
const AdvancedTables = React.lazy(() => import('pages/tables/Advanced'));

// widgets
const Widgets = React.lazy(() => import('pages/uikit/Widgets'));

// maps
const GoogleMaps = React.lazy(() => import('pages/maps/GoogleMaps'));
const VectorMaps = React.lazy(() => import('pages/maps/VectorMaps'));

const loading = () => <div className=""></div>;

type LoadComponentProps = {
    component: React.LazyExoticComponent<() => JSX.Element>;
};

const LoadComponent = ({ component: Component }: LoadComponentProps) => (
    <Suspense fallback={loading()}>
        <Component />
    </Suspense>
);

const AllRoutes = () => {
    const { appSelector } = useRedux();

    const { layout } = appSelector((state) => ({
        layout: state.Layout,
    }));

    const getLayout = () => {
        let layoutCls: React.ComponentType = VerticalLayout;

        switch (layout.layoutType) {
            case LayoutTypes.LAYOUT_HORIZONTAL:
                layoutCls = HorizontalLayout;
                break;
            case LayoutTypes.LAYOUT_DETACHED:
                layoutCls = DetachedLayout;
                break;
            case LayoutTypes.LAYOUT_FULL:
                layoutCls = FullLayout;
                break;
            default:
                layoutCls = VerticalLayout;
                break;
        }
        return layoutCls;
    };
    let Layout = getLayout();

    return useRoutes([
        {
            path: '/',
            element: <Root />,
        },
        {
            // public routes
            path: '/',
            element: <DefaultLayout />,
            children: [
                {
                    path: 'login',
                    children: [
                        {
                            path: 'callback',
                            element: (
                                <LoginCallback
                                    loadingElement={<Redirecting />}
                                    errorComponent={(data) => {
                                        LocalStorage.setOktaExceptionMessage(data);
                                        return RedirectLogout({ url: `account/auth-error` });
                                    }}
                                />
                            ),
                        },
                    ],
                },
                {
                    path: 'account',
                    children: [
                        { path: 'login', element: <LoadComponent component={Login} /> },
                        { path: 'access-denied', element: <LoadComponent component={AccessDenied} /> },
                        { path: 'auth-error', element: <LoadComponent component={AuthError} /> },
                        { path: 'lock-screen', element: <LoadComponent component={LockScreen} /> },
                        { path: 'logout', element: <LoadComponent component={Logout} /> },
                        { path: 'confirm2', element: <LoadComponent component={Confirm2} /> },
                        { path: 'confirm', element: <LoadComponent component={Confirm} /> },
                        { path: 'lock-screen2', element: <LoadComponent component={LockScreen2} /> },
                    ],
                },
                {
                    path: 'error-404',
                    element: <LoadComponent component={ErrorPageNotFound} />,
                },
                {
                    path: 'error-500',
                    element: <LoadComponent component={ServerError} />,
                },
                {
                    path: 'maintenance',
                    element: <LoadComponent component={Maintenance} />,
                },
                {
                    path: 'landing',
                    element: <LoadComponent component={Landing} />,
                },
            ],
        },
        {
            // auth protected routes
            path: '/',
            element: (
                <PrivateRoute
                    Component={Layout}
                    roles={[OPERATIONS_ROLE, ROUTING_ROLE, SALES_ROLE, MANAGER_ROLE, CUSTOMER_SERVICE_ROLE]}
                />
            ),
            children: [
                {
                    path: 'home',
                    element: <LoadComponent component={Home} />,
                },
                {
                    path: 'roles-management',
                    children: [
                        {
                            path: 'permissions',
                            element: <LoadComponent component={Permissions} />,
                        },
                        {
                            path: 'groups',
                            element: <LoadComponent component={Groups} />,
                        },
                    ],
                },
                {
                    path: 'routing',
                    children: [
                        {
                            path: 'routing-tasks',
                            element: <LoadComponent component={RoutingTasks} />,
                        },
                        {
                            path: 'pickups',
                            element: <LoadComponent component={Pickups} />,
                        },
                        {
                            path: 'routing-solutions/routing-solutions-details',
                            element: <LoadComponent component={RouteSolutionMap} />,
                        },
                        {
                            path: 'routing-solutions/routing-solutions-detailsV2',
                            element: <LoadComponent component={RouteSolutionDetailV2} />,
                        },
                        {
                            path: '3rd-party-routing',
                            element: <LoadComponent component={ThirdPartyRouting} />,
                        },
                    ],
                },
                {
                    path: 'dashboards',
                    children: [
                        {
                            path: 'warehouse-metrics',
                            element: <LoadComponent component={WarehouseMetrics} />,
                        },
                    ],
                },
                {
                    path: 'fleet-ops',
                    children: [
                        {
                            path: 'fleet-view',
                            element: <LoadComponent component={FleetView} />,
                        },
                        {
                            path: 'zip-based-routing',
                            element: <LoadComponent component={ZipBasedRouting} />,
                        },
                        {
                            path: 'driver-assignment',
                            element: <LoadComponent component={DriverAssignment} />,
                        },
                        {
                            path: 'package-inquiry',
                            element: <LoadComponent component={PackageInquiry} />,
                        },
                    ],
                },
                {
                    path: 'better-trucks',
                    children: [
                        {
                            path: 'companies',
                            element: <LoadComponent component={Companies} />,
                        },
                        {
                            path: 'companies/:companyId',
                            element: <LoadComponent component={CompanyDetails} />,
                        },
                        {
                            path: 'cities',
                            element: <LoadComponent component={Cities} />,
                        },
                        {
                            path: 'company-hubs',
                            element: <LoadComponent component={CompanyHubs} />,
                        },
                        {
                            path: 'users',
                            element: <LoadComponent component={Users} />,
                        },
                        {
                            path: 'zip-codes',
                            element: <LoadComponent component={ZipCodes} />,
                        },
                        {
                            path: 'zip-code-exceptions',
                            element: <LoadComponent component={ZipCodeManagement} />,
                        },
                        {
                            path: 'modify-zip-code',
                            element: <LoadComponent component={ModifyZipcodes} />,
                        },
                        {
                            path: 'vehicle-templates',
                            element: <LoadComponent component={Vehicles} />,
                        },
                        {
                            path: 'shipments',
                            element: <LoadComponent component={Shipments} />,
                        },
                        {
                            path: 'routing-tasks',
                            element: <LoadComponent component={ELSRoutingTasks} />,
                        },
                        {
                            path: 'templates',
                            element: <LoadComponent component={TemplatesTasks} />,
                        },
                        {
                            path: 'ops-hubs',
                            element: <LoadComponent component={OpsHubs} />,
                        },
                    ],
                },
                {
                    path: 'apps',
                    children: [
                        {
                            path: 'calendar',
                            element: <LoadComponent component={CalendarApp} />,
                        },
                        {
                            path: 'chat',
                            element: <LoadComponent component={ChatApp} />,
                        },
                        {
                            path: 'crm',
                            children: [
                                {
                                    path: 'dashboard',
                                    element: <LoadComponent component={CRMDashboard} />,
                                },
                                {
                                    path: 'projects',
                                    element: <LoadComponent component={CRMProjects} />,
                                },
                                {
                                    path: 'management',
                                    element: <LoadComponent component={CRMManagement} />,
                                },
                                {
                                    path: 'clients',
                                    element: <LoadComponent component={CRMClients} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={CRMOrderList} />,
                                },
                            ],
                        },
                        {
                            path: 'ecommerce',
                            children: [
                                {
                                    path: 'products',
                                    element: <LoadComponent component={EcommerceProducts} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProductDetails} />,
                                },
                                {
                                    path: 'orders',
                                    element: <LoadComponent component={Orders} />,
                                },
                                {
                                    path: 'order/details',
                                    element: <LoadComponent component={OrderDetails} />,
                                },
                                {
                                    path: 'customers',
                                    element: <LoadComponent component={Customers} />,
                                },
                                {
                                    path: 'shopping-cart',
                                    element: <LoadComponent component={Cart} />,
                                },
                                {
                                    path: 'checkout',
                                    element: <LoadComponent component={Checkout} />,
                                },
                                {
                                    path: 'sellers',
                                    element: <LoadComponent component={Sellers} />,
                                },
                            ],
                        },
                        {
                            path: 'email',
                            children: [
                                {
                                    path: 'inbox',
                                    element: <LoadComponent component={Inbox} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={EmailDetail} />,
                                },
                            ],
                        },
                        {
                            path: 'tasks',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={TaskList} />,
                                },
                                {
                                    path: 'kanban',
                                    element: <LoadComponent component={Kanban} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={TaskDetails} />,
                                },
                            ],
                        },

                        {
                            path: 'projects',
                            children: [
                                {
                                    path: 'list',
                                    element: <LoadComponent component={ProjectList} />,
                                },
                                {
                                    path: 'details',
                                    element: <LoadComponent component={ProjectDetail} />,
                                },
                                {
                                    path: 'gantt',
                                    element: <LoadComponent component={ProjectGannt} />,
                                },
                                {
                                    path: 'new',
                                    element: <LoadComponent component={ProjectForm} />,
                                },
                            ],
                        },
                        {
                            path: 'social',
                            element: <LoadComponent component={SocialFeed} />,
                        },
                        {
                            path: 'file',
                            element: <LoadComponent component={FileManager} />,
                        },
                    ],
                },
                {
                    path: 'pages',
                    children: [
                        {
                            path: 'starter',
                            element: <LoadComponent component={Starter} />,
                        },
                        {
                            path: 'profile',
                            element: <LoadComponent component={Profile} />,
                        },
                        {
                            path: 'profile2',
                            element: <LoadComponent component={Profile2} />,
                        },
                        {
                            path: 'profile-okta',
                            element: <LoadComponent component={ProfileOkta} />,
                        },
                        {
                            path: 'pricing',
                            element: <LoadComponent component={Pricing} />,
                        },
                        {
                            path: 'error-404-alt',
                            element: <LoadComponent component={ErrorPageNotFoundAlt} />,
                        },
                        {
                            path: 'timeline',
                            element: <LoadComponent component={Timeline} />,
                        },
                        {
                            path: 'invoice',
                            element: <LoadComponent component={Invoice} />,
                        },
                        {
                            path: 'faq',
                            element: <LoadComponent component={FAQ} />,
                        },
                        {
                            path: 'preloader',
                            element: <LoadComponent component={PreLoader} />,
                        },
                    ],
                },
                {
                    path: 'ui',
                    children: [
                        {
                            path: 'base-ui',
                            children: [
                                {
                                    path: 'accordions',
                                    element: <LoadComponent component={Accordions} />,
                                },
                                {
                                    path: 'alerts',
                                    element: <LoadComponent component={Alerts} />,
                                },
                                {
                                    path: 'avatars',
                                    element: <LoadComponent component={Avatars} />,
                                },
                                {
                                    path: 'badges',
                                    element: <LoadComponent component={Badges} />,
                                },
                                {
                                    path: 'breadcrumb',
                                    element: <LoadComponent component={Breadcrumbs} />,
                                },
                                {
                                    path: 'buttons',
                                    element: <LoadComponent component={Buttons} />,
                                },
                                {
                                    path: 'cards',
                                    element: <LoadComponent component={Cards} />,
                                },
                                {
                                    path: 'carousel',
                                    element: <LoadComponent component={Carousels} />,
                                },
                                {
                                    path: 'dropdowns',
                                    element: <LoadComponent component={Dropdowns} />,
                                },
                                {
                                    path: 'embedvideo',
                                    element: <LoadComponent component={EmbedVideo} />,
                                },
                                {
                                    path: 'grid',
                                    element: <LoadComponent component={Grid} />,
                                },
                                {
                                    path: 'listgroups',
                                    element: <LoadComponent component={ListGroups} />,
                                },
                                {
                                    path: 'modals',
                                    element: <LoadComponent component={Modals} />,
                                },
                                {
                                    path: 'notifications',
                                    element: <LoadComponent component={Notifications} />,
                                },
                                {
                                    path: 'offcanvas',
                                    element: <LoadComponent component={Offcanvases} />,
                                },
                                {
                                    path: 'placeholders',
                                    element: <LoadComponent component={Placeholders} />,
                                },
                                {
                                    path: 'paginations',
                                    element: <LoadComponent component={Paginations} />,
                                },
                                {
                                    path: 'popovers',
                                    element: <LoadComponent component={Popovers} />,
                                },
                                {
                                    path: 'progress',
                                    element: <LoadComponent component={Progress} />,
                                },
                                {
                                    path: 'ribbons',
                                    element: <LoadComponent component={Ribbons} />,
                                },
                                {
                                    path: 'spinners',
                                    element: <LoadComponent component={Spinners} />,
                                },
                                {
                                    path: 'tabs',
                                    element: <LoadComponent component={Tabs} />,
                                },
                                {
                                    path: 'tooltips',
                                    element: <LoadComponent component={Tooltips} />,
                                },
                                {
                                    path: 'typography',
                                    element: <LoadComponent component={Typography} />,
                                },
                            ],
                        },
                        {
                            path: 'widgets',
                            element: <LoadComponent component={Widgets} />,
                        },
                        {
                            path: 'extended',
                            children: [
                                {
                                    path: 'dragdrop',
                                    element: <LoadComponent component={DragDrop} />,
                                },
                                {
                                    path: 'rangesliders',
                                    element: <LoadComponent component={RangeSliders} />,
                                },
                                {
                                    path: 'ratings',
                                    element: <LoadComponent component={Ratings} />,
                                },
                            ],
                        },
                        {
                            path: 'icons',
                            children: [
                                {
                                    path: 'unicons',
                                    element: <LoadComponent component={Unicons} />,
                                },
                                {
                                    path: 'mdi',
                                    element: <LoadComponent component={MDIIcons} />,
                                },
                                {
                                    path: 'dripicons',
                                    element: <LoadComponent component={Dripicons} />,
                                },
                            ],
                        },
                        {
                            path: 'forms',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicForms} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={FormAdvanced} />,
                                },
                                {
                                    path: 'validation',
                                    element: <LoadComponent component={FormValidation} />,
                                },
                                {
                                    path: 'wizard',
                                    element: <LoadComponent component={FormWizard} />,
                                },
                                {
                                    path: 'upload',
                                    element: <LoadComponent component={FileUpload} />,
                                },
                                {
                                    path: 'editors',
                                    element: <LoadComponent component={Editors} />,
                                },
                            ],
                        },
                        {
                            path: 'tables',
                            children: [
                                {
                                    path: 'basic',
                                    element: <LoadComponent component={BasicTables} />,
                                },
                                {
                                    path: 'advanced',
                                    element: <LoadComponent component={AdvancedTables} />,
                                },
                            ],
                        },
                        {
                            path: 'charts',
                            children: [
                                {
                                    path: 'apex',
                                    element: <LoadComponent component={ApexChart} />,
                                },
                                {
                                    path: 'chartjs',
                                    element: <LoadComponent component={ChartJs} />,
                                },
                            ],
                        },
                        {
                            path: 'maps',
                            children: [
                                {
                                    path: 'googlemaps',
                                    element: <LoadComponent component={GoogleMaps} />,
                                },
                                {
                                    path: 'vectormaps',
                                    element: <LoadComponent component={VectorMaps} />,
                                },
                            ],
                        },
                    ],
                },
                {
                    path: 'customer-service',
                    children: [
                        {
                            path: 'pod-review',
                            element: <LoadComponent component={PodReview} />,
                        },
                        {
                            path: 'claims',
                            element: <LoadComponent component={Claims} />,
                        },
                        {
                            path: 'service-levels',
                            element: <LoadComponent component={ServiceLevels} />,
                        },
                        // {
                        //     path: 'track-shipment',
                        //     element: <LoadComponent component={TrackShipment} />,
                        // },
                        {
                            path: 'track-shipments',
                            element: <LoadComponent component={TrackShipments} />,
                        },
                        {
                            path: 'service-levels-exceptions',
                            element: <LoadComponent component={ServiceLevelExceptions} />,
                        },
                        {
                            path: 'sftp',
                            element: <LoadComponent component={SftpView} />,
                        },
                        {
                            path: 'event-types',
                            element: <LoadComponent component={EventTypes} />,
                        },
                        {
                            path: 'address-corrections-notifications',
                            element: <LoadComponent component={AddressCorrectionsNotifications} />,
                        },
                    ],
                },
                {
                    path: 'rates-management',
                    children: [
                        {
                            path: 'basic-rates',
                            element: <LoadComponent component={BasicRates} />,
                        },
                    ],
                },

                {
                    path: 'dynamic-pricing',
                    children: [
                        {
                            path: 'all-pricing-rules',
                            element: <LoadComponent component={AllPricingRules} />,
                        },
                        {
                            path: 'all-company-pricing-rules',
                            element: <LoadComponent component={AllCompanyPricingRules} />,
                        },
                        {
                            path: 'pricing-rule',
                            element: <LoadComponent component={CreatePricingRule} />,
                        },
                        {
                            path: 'active-pricing-rules',
                            element: <LoadComponent component={ViewActivePricingRule} />,
                        },
                        // {
                        //     path: 'net-base-rate',
                        //     element: <LoadComponent component={CompanyDiscounts} />,
                        // },
                    ],
                },
                // {
                //     path: 'verify-label',
                //     element: <LoadComponent component={OrphanLabels} />,
                // },
                {
                    path: 'verify-label-add-package',
                    element: <LoadComponent component={OrphansPackage} />,
                },
                // {
                //     path: 'add-package',
                //     element: <LoadComponent component={APICustomers} />,
                // },
                {
                    path: 'container-label',
                    element: <LoadComponent component={ContainerLabels} />,
                },
                {
                    path: 'labels',
                    element: <LoadComponent component={Labels} />,
                },
                {
                    path: 'labels/:btContainerId/details',
                    element: <LoadComponent component={ContainerParcels} />,
                },
                {
                    path: 'company-container-label',
                    element: <LoadComponent component={CompanyContainerLabels} />,
                },
                {
                    path: 'track-containers',
                    element: <LoadComponent component={TrackingContainers} />,
                },
                {
                    path: 'container-hub-config',
                    element: <LoadComponent component={ContainerHubConfig} />,
                },
                {
                    path: 'line-haul-config',
                    element: <LoadComponent component={LineHaulConfig} />,
                },
                {
                    path: 'third-party-carriers',
                    children: [
                        {
                            path: 'carriers',
                            element: <LoadComponent component={Carriers} />,
                        },
                        {
                            path: 'carrier-zips',
                            element: <LoadComponent component={CarrierZips} />,
                        },
                    ],
                },
            ],
        },
    ]);
};

export { AllRoutes };
