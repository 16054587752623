import LocalStorage from 'helpers/api/LocalStorage';
import RedirectLogout from './RedirectLogout';

const Logout = () => {
    LocalStorage.setLoggedInUser(null);
    return (
        <RedirectLogout  />
    );
};

export default Logout;
