import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Row, Col } from 'react-bootstrap';
import AccountLayout from './AccountLayout';
import { useOktaAuth } from '@okta/okta-react';
import { useEffect, } from 'react';

const BottomLink = () => {
    const { t } = useTranslation();

    return (
        <Row className="mt-3">
            <Col className="text-center">
                <p className="text-muted">
                    {t('To ')}{' '}
                    <Link to={'/account/login'} className="text-muted ms-1">
                        <b>{t('Log In')}</b>
                    </Link>
                </p>
            </Col>
        </Row>
    );
};


export type LogoutParameters = {
    url?: String;
  }

const RedirectLogout = (data?: LogoutParameters) => {
    const { t } = useTranslation();
    const { oktaAuth } = useOktaAuth();

    const searchParams = new URLSearchParams();
    searchParams.append("returnTo", `${window.location.origin}/${data?.url ?? 'account/login'}`);
    searchParams.append("client_id", oktaAuth.options.clientId!);
    const query = searchParams.toString();
    
    window.location.href = `${oktaAuth.options.issuer}/v2/logout?${query}`;
    
    return (
        <AccountLayout bottomLinks={<BottomLink />}>
            <div className="text-center w-75 m-auto">
                <h4 className="text-dark-50 text-center mt-0 fw-bold">{t('Redirecting for sign out')}</h4>
            </div>
        </AccountLayout>
    );
};

export default RedirectLogout;
